html {
    background-color: #ffffff;
    box-sizing: border-box;
}

body {
    font-family: 'Montserrat', sans-serif;
    background-color: #272727;
}

.body-wrapper {
    overflow: hidden;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        padding: 0 20px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}

h1 {
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    padding-top: 25px;
    border-top: 1px solid grey;
    padding-bottom: 85px;
}

h1 span {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 23px;
    line-height: 40px;
}

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 24px;
    }
}

h1 .thin {
    font-weight: 300;
}

h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 62px;
    margin-bottom: 25px;
    padding-top: 60px;
    text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
    h2 {
        font-size: 28px;
        line-height: 36px;
    }
}

p {
    font-size: 20px;
    line-height: 40px;
    font-weight: 300;
}
b {
    font-weight: 600;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

/* Banner */
.banner {
    background: #ffffff;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center;
    grid-template-columns: 0.8fr 2fr 0.7fr;
    position: relative;
    z-index: 100;
}
.banner .logo-box {
    background: #eaf2f8;
    border-radius: 10px;
}
.banner .logo-box a {
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
}
.banner .info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7d7f82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
}
.banner .info:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #f27558;
}
.banner .domaner-btn {
    background: #f27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .banner .info {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
@media only screen and (max-width: 940px) {
    .banner {
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a {
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title {
        line-height: 24px;
        text-align: center;
    }
    .banner .info {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before {
        content: none;
    }
}
@media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
}

.site-logo {
    font-weight: bold;
    font-size: 18px;
    color: #040603;
}

.logo-wrapper {
    padding-top: 25px;
    padding-bottom: 22px;
}
.site-header {
    background-color: #ffaf00;
}

.hero-wrapper {
    display: grid;
    grid-template-columns: 1fr 25% 1fr;
    grid-gap: 50px;
    margin-bottom: -312px;
}

.hero-image {
    background-image: url(../image/hero-shoe.png);
    width: 300px;
    height: 340px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 25px;
    position: relative;
    filter: drop-shadow(25px 25px 25px rgba(0, 0, 0, 0.25));
    transition: filter 0.4s;
}

.hero-image:hover {
    filter: drop-shadow(25px 25px 25px rgba(0, 0, 0, 0.4));
}

.hero-image a {
    color: #141414;
    font-size: 25px;
    position: absolute;
    bottom: 30px;
    left: 103px;
    display: flex;
}

.arrow {
    background-image: url(../image/arrow.png);
    width: 25px;
    height: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
    background-position: bottom;
}

.hero-content {
    background: #141414;
    border-radius: 0px 0px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -10px;
}

.logo {
    background-image: url(../image/logo-min.webp);
    background-repeat: no-repeat;
    background-size: contain;
    height: 240px;
    width: 69px;
    background-position: center;
    color: transparent;
}

.hoodie {
    margin-top: 70px;
    position: relative;
    z-index: 3;
}

.hoodie::before {
    content: '';
    position: absolute;
    width: 900px;
    height: 900px;
    background: #ffaf00;
    opacity: 0.4;
    filter: blur(300px);
    z-index: -1;
    left: 50px;
    top: -236px;
}

.hoodie-image {
    background-image: url(../image/hoodie1.webp);
    width: 355px;
    height: 603px;
    filter: drop-shadow(25px 25px 25px rgba(0, 0, 0, 0.25));
    border-radius: 20px;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    float: right;
    transition: filter 0.4s;
}

.hoodie-image:hover {
    filter: drop-shadow(25px 25px 25px rgba(0, 0, 0, 0.4));
}

.hoodie-image a {
    position: absolute;
    color: #fff;
    font-size: 25px;
    bottom: 65px;
    left: 100px;
}

.cap-image a {
    position: absolute;
    color: #fff;
    font-size: 25px;
    bottom: 65px;
    left: 90px;
}

.cap {
    margin-top: 70px;
}

.cap-image {
    background-image: url(../image/cap1.webp);
    width: 355px;
    height: 603px;
    filter: drop-shadow(25px 25px 25px rgba(0, 0, 0, 0.25));
    border-radius: 20px;
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    transition: filter 0.4s;
}

.cap-image:hover {
    filter: drop-shadow(25px 25px 25px rgba(0, 0, 0, 0.4));
}

.site-header {
    position: relative;
    z-index: 2;
}

.site-header::before {
    position: absolute;
    content: '';
    background-image: url(../image/hero-rect1.png);
    width: 1581px;
    height: 200px;
    left: 0px;
    top: 135px;
}

.cap::after {
    position: absolute;
    content: '';
    background-image: url(../image/hero-rect2.png);
    width: 1581px;
    height: 200px;
    left: 350px;
    top: 272px;
    background-repeat: no-repeat;
    z-index: -1;
}

.cap {
    position: relative;
    z-index: 3;
}

.hero-main {
    position: relative;
    z-index: 10;
}

.cap::before {
    content: '';
    position: absolute;
    width: 925px;
    height: 913px;
    left: -340px;
    top: -350px;
    background: #ffffff;
    opacity: 0.4;
    filter: blur(200px);
    z-index: -1;
}

.header-description {
    padding-top: 350px;
    text-align: center;
    color: #fff;
}

.main {
    text-align: center;
    color: #fff;
}

p:not(:last-child) {
    padding-bottom: 40px;
}

.clothing {
    padding-bottom: 100px;
}

.nft-shoes-wrapper {
    display: grid;
    grid-template-columns: 35% 1fr;
}

.nft-shoe-img {
    position: relative;
    z-index: 2;
    padding-top: 45px;
}

.nft-shoe-content {
    position: relative;
    z-index: 2;
}

.nft-shoe-img-inner {
    background-image: url(../image/nft-shoe-min.webp);
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 733px;
    margin-top: 25px;
    margin-left: 25px;
    position: relative;
}

.nft-shoe-img-inner::before {
    content: '';
    width: 404.46px;
    height: 414.9px;
    position: absolute;
    background: #ffaf00;
    opacity: 0.4;
    filter: blur(130px);
    transform: rotate(-2.76deg);
    z-index: -1;
    top: 110px;
    left: 90px;
}

.nft-shoe-img::before {
    content: '';
    position: absolute;
    width: 275px;
    height: 255px;
    background: #ffaf00;
    border-radius: 20px;
    left: 0;
    z-index: -1;
}

.acessories-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 131px 0 131px;
}

.acessories-grid li {
    padding-bottom: 40px;
    text-align: left;
    padding-left: 80px;
    font-weight: 700;
    font-size: 22px;
    position: relative;
}

.acessories-grid li::before {
    content: '';
    position: absolute;
    background-image: url(../image/grid-square.png);
    width: 7px;
    background-size: contain;
    height: 7px;
    top: 10px;
    left: 57px;
}

.ordered-list li {
    margin-bottom: 20px;
    font-weight: 300;
}

.yellow {
    background-color: #ffaf00;
    padding-bottom: 100px;
    overflow: hidden;
    color: #313131;
}

.yellow h2 {
    position: relative;
    z-index: 2;
}

.yellow h2::after {
    content: '';
    position: absolute;
    background-image: url(../image/rocket.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 434px;
    height: 434px;
}

.yellow h2::before {
    content: '';
    position: absolute;
    width: 612px;
    height: 444px;
    right: -257px;
    top: -43px;
    background: #ffffff;
    opacity: 0.5;
    filter: blur(195px);
    z-index: -1;
}

.yellow p {
    position: relative;
    z-index: 2;
}

.contact-btn {
    background-color: #ffaf00;
    border: 1px solid #ffaf00;
    border-radius: 20px;
    font-size: 22px;
    font-weight: 700;
    padding: 15px 55px;
    transition: background-color 0.2s;
    position: relative;
    color: white;
}

.contact-btn:hover {
    background-color: #ff9900;
}

/*
.contact-btn:hover::before {
  content: "Services under development!";
  position: absolute;
  font-size: 15px;
  font-weight: 300;
  top: -56px;
    left: 4px;
    width: fit-content;
    background-color: #4a4848;
    border-radius: 13px;
    padding: 10px;
    color: #fff;
}

.contact-btn:hover::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: -26px;
  left: 50%;
  transform: rotate(45deg);
  background-color: #4a4848;
}
*/

.accessories {
    padding-bottom: 25px;
}

footer {
    padding: 15px;
    background-color: #141414;
    margin-top: 60px;
    color: #616161;
}

footer .container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-nft {
    display: none;
}

/* MOBILE RESPONSIVE  */

@media only screen and (max-width: 768px) {
    footer .container {
        flex-direction: column;
        padding-bottom: 15px;
        padding-top: 15px;
    }
}

footer .copyright {
    color: #828c98;
    font-size: 14px;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

@media only screen and (max-width: 1200px) {
    .hero-wrapper {
        grid-gap: 20px;
    }
    .hero-image {
        width: 240px;
    }

    .hero-image a {
        bottom: 85px;
        left: 80px;
    }

    .cap-image,
    .hoodie-image {
        width: 320px;
    }

    .cap-image a {
        bottom: 130px;
        left: 65px;
    }

    .hoodie-image a {
        bottom: 130px;
    }
    .nft-shoe-img {
        padding-top: 180px;
    }

    h1 span {
        font-size: 20px;
    }
    .header-description {
        padding-top: 270px;
    }

    .acessories-grid li {
        padding-left: 50px;
    }

    .acessories-grid li::before {
        left: 25px;
    }
}

@media only screen and (max-width: 990px) {
    header,
    footer,
    .container {
        padding: 0px 22px;
    }

    h1 {
        font-size: 16px;
    }

    h1 span {
        font-size: 23px;
    }

    h2 {
        font-size: 30px;
    }

    p {
        font-size: 20px;
    }

    #nft-shoes {
        display: none;
    }

    .acessories-grid {
        padding: 0 50px 0 50px;
    }

    .acessories-grid li {
        padding-left: 0;
        padding-bottom: 25px;
    }

    .acessories-grid li::before {
        left: -20px;
    }

    .grid1,
    .grid2,
    .grid3 {
        display: flex;
        justify-content: center;
        padding-bottom: 25px;
    }

    .ordered-list {
        padding-bottom: 25px;
    }

    .mobile-nft {
        display: block;
    }

    .hoodie,
    .cap,
    .hero-image a,
    .site-header::before {
        display: none;
    }

    .hero-wrapper,
    .nft-shoes-wrapper,
    .banner-wrapper {
        grid-template-columns: 1fr;
        padding: 0 22px;
    }

    .hero-image {
        margin: 25px auto 0;
        width: 100%;
        height: 678px;
        background-position-x: center;
    }

    .header-description {
        padding-top: 300px;
    }

    .nft-shoe-img-inner {
        background-position-x: center;
        height: 578px;
    }
    .nft-shoe-img::before {
        width: 225px;
        left: 155px;
    }

    .nft-shoe-sect {
        overflow: hidden;
    }

    .yellow h2::after {
        left: 278px;
    }
    .yellow h2::before {
        right: -130px;
    }

    .mobile-detector {
        display: none;
    }

    /* .m-visible {
    display: initial;
  }

  .m-hidden {
    display: none;
  } */

    footer .logo-wrapper {
        padding-bottom: 8px;
    }
}

@media only screen and (max-width: 767px) {
    .header-description {
        padding-top: 155px;
    }
    .acessories-grid {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 550px) {
    .header-description {
        padding-top: 80px;
    }
}

@media only screen and (max-width: 500px) {
    .hero-image {
        height: 608px;
    }
    .header-description {
        padding-top: 140px;
    }

    header {
        padding: 0;
    }
}

@media only screen and (max-width: 450px) {
    .hero-image {
        height: 558px;
    }

    .header-description {
        padding-top: 130px;
    }
}

@media only screen and (max-width: 400px) {
    .hero-image {
        height: 485px;
    }

    .hero-wrapper {
        padding: 0;
    }

    .header-description {
        padding-top: 225px;
    }
}

@media only screen and (max-width: 350px) {
    .hero-image {
        height: 440px;
    }
}

@media only screen and (max-width: 747px) {
    .nft-shoe-img::before {
        left: 75px;
    }
}

@media only screen and (max-width: 425px) {
    .nft-shoe-img::before {
        left: 16px;
    }
}

@media only screen and (max-width: 375px) {
    .nft-shoe-img::before {
        left: 2px;
    }
}

@media only screen and (max-width: 320px) {
    header,
    footer,
    .container {
        padding: 0px 4px;
    }
    .nft-shoe-img::before {
        left: 6px;
    }
    .nft-shoe-content {
        margin-top: -60px;
    }
}
